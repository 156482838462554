import React, { useRef, useEffect, useState } from "react";
import Underline from "../Underline/Underline";

const DivHeading = ({ text, desc }) => {
  const headingRef = useRef(null);
  const [underlineWidth, setUnderlineWidth] = useState("0px");

  const updateUnderlineWidth = () => {
    if (headingRef.current) {
      setUnderlineWidth(`${headingRef.current.offsetWidth}px`);
    }
  };

  useEffect(() => {
    updateUnderlineWidth();
    window.addEventListener("resize", updateUnderlineWidth);

    return () => {
      window.removeEventListener("resize", updateUnderlineWidth);
    };
  }, [text]);

  return (
    <div className="p-5">
      <h1
        style={{
          fontFamily: "Yeseva One",
          fontSize: "26px",
          fontWeight: 400,
          lineHeight: "28.6px",
          letterSpacing: "1px",
          textAlign: "center",
          margin: "0",
        }}
        className="my-4"
      >
        <span ref={headingRef}> {text} </span>
      </h1>
      {desc ? (
        <h5 className="p-3"
          style={{
            fontFamily: "Inter",
            fontSize: "14.25px",
            fontWeight: "400",
            lineHeight: "22.86px",
            textAlign: "center",
            color:'#B7177B'
          }}
        >
          {desc}
        </h5>
      ) : (
        ""
      )}
      <Underline width={underlineWidth} className="my-2" />
    </div>
  );
};

export default DivHeading;
