import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById } from "../redux/order"; // Adjust import path
import { setProductRating } from "../redux/product"; // Adjust import path
import DivHeading from "../Components/DivHeading/Divheading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const MyOrders = () => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.orderSlice);

  // State to temporarily store the clicked rating
  const [tempRatings, setTempRatings] = useState({});

  useEffect(() => {
    dispatch(getOrderById());
  }, [dispatch]);

  // Ensure order is an array
  const ordersArray = Array.isArray(order) ? order : [];

  console.log("ordersArray", ordersArray);

  // Function to render stars and handle click events
  const renderStars = (order, product, rating) => {
    const totalStars = 5;
    const currentRating = tempRatings[product._id] || rating;

    return Array.from({ length: totalStars }, (_, index) => (
      <FontAwesomeIcon
        icon={faStar}
        key={index}
        className={index < currentRating ? 'text-yellow-500' : 'text-gray-300'}
        style={{ color: index < currentRating ? "#e1ca77" : "#BEBEBE", cursor: "pointer" }}
        onClick={() => handleRatingClick(order, product, index + 1)}
      />
    ));
  };

  // Function to handle star click and dispatch the rating
  const handleRatingClick = (order, product, rating) => {
    // Update the temporary rating immediately
    setTempRatings((prevRatings) => ({
      ...prevRatings,
      [product._id]: rating,
    }));

    // Dispatch the action to update the rating in the Redux store
    dispatch(setProductRating({ productId: product.product, rating, orderId: order._id }));
  };

  return (
    <>
      <div className="flex flex-col gap-5 w-10/12 mx-auto my-5">
        <DivHeading text='My Order History' />
        <div className="w-full max-w-4xl mx-auto">
          <div className="overflow-x-auto hide-scrollbar">
            <table className="min-w-full bg-white border-b-2 border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b text-left text-sm font-medium text-center text-gray-600">
                    Product
                  </th>
                  <th className="px-4 py-2 border-b text-left text-sm font-medium text-center text-gray-600">
                    Order Id
                  </th>
                  <th className="px-4 py-2 border-b text-left text-sm font-medium text-center text-gray-600">
                    Quantity
                  </th>
                  <th className="px-4 py-2 border-b text-left text-sm font-medium text-center text-gray-600">
                    Subtotal
                  </th>
                  <th className="px-4 py-2 border-b text-left text-sm font-medium text-center text-gray-600">
                    Your Product Rating
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersArray.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                      No orders found
                    </td>
                  </tr>
                ) : (
                  ordersArray.map((order) =>
                    order.products.map((product) => (
                      <tr key={product._id}>
                        <td className="px-4 py-2">
                          <div className="flex">
                            <div className="w-1/4">
                              {product.product?.image && (
                                <img src={product.product.image} alt={product?.productName} className="w-16 h-16 object-cover" />
                              )}
                            </div>
                            <div className="w-3/4 flex flex-col justify-center pl-4">
                              <div>{product?.productName || 'Unknown Product'}</div>
                              {/* <div className="text-gray-500">
                                {product?.color || 'No color'}
                              </div> */}
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">{order.orderNumber}</td>
                        <td className="px-4 py-2">
                          <div className="flex items-center justify-center">
                            <span className="mx-2">{product.quantity}</span>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-center">{order.totalAmount}</td>
                        <td className="px-4 py-2 text-center">
                         
                         
                            {renderStars(order, product, product?.productRating)}
                         
                        </td>
                      </tr>
                    ))
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
