import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFavoriteProduct, fetchFavoriteProducts, removeFromFavorite } from '../../redux/customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import './ProductCard.css';
import ComponentLoader from '../ComponentLoader/ComponentLoader';
import ConfirmationPopup from '../../Popups/ConfirmationPopup/ConfrimationPopup'; // Adjust path as needed
import { useAuth } from '../../Routes/AuthContext';
const ProductCard = ({
  id,
  imageUrl,
  productName,
  rating=5,
  description,
  price,
  productBrand,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [actionType, setActionType] = useState(null); // 'add' or 'remove'
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    dispatch(fetchFavoriteProducts());
  }, [dispatch]);

  const { favoriteProducts, isFetchingFetchFavorites } = useSelector(state => state.customerSlice);

  useEffect(() => {
    if (favoriteProducts && favoriteProducts.length > 0) {
      setIsFavorite(favoriteProducts.some(product => product?._id === id));
    }
  }, [favoriteProducts, id]);

  const handleToggleFavorite = (e) => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
  }
    e.stopPropagation();
    setActionType(isFavorite ? 'remove' : 'add');
    setShowConfirmPopup(true);
  };

  const handleConfirm = () => {
    if (actionType === 'add') {
      dispatch(addFavoriteProduct(id));
    } else if (actionType === 'remove') {
      dispatch(removeFromFavorite(id));
    }
    setIsFavorite(prev => !prev);
  };

  const renderStars = (rating) => {
    const currentRating = Math.floor(rating);
    const totalStars = 5;

    return Array.from({ length: totalStars }, (_, index) => (
      <FontAwesomeIcon
        icon={faStar}
        key={index}
        className={index < currentRating ? 'text-yellow-500' : 'text-gray-300'}
        style={{ color: index < currentRating ? "#e1ca77" : "#BEBEBE", cursor: "pointer" }}
    
      />
    ));
  };

  const showProductDesc = () => {
    navigate(`/productdesc/${id}`);
  };

  return (
    <>
      {isFetchingFetchFavorites && <ComponentLoader />}
      <div
        className="bg-white shadow-lg overflow-hidden w-full mx-auto cursor-pointer"
        onClick={showProductDesc}
      >
        <div className="relative w-full">
          <img
            src={imageUrl}
            alt={productName}
            className="w-full object-cover h-80"
            // className="object-fit h-80 w-full"
          />
        </div>

        <div className="p-4">
          <div className="flex flex-col justify-between items-start mb-2">
            <h2 className="text-xl">{productName}</h2>
            <div className="flex items-center">
              <span className="text-gray-700">
                {rating?.toFixed(1)}
              </span>
              <span className="flex p-2">{renderStars(rating)}</span>
            </div>
          </div>
          {/* <h2 className="text-md">Brand: {productBrand}</h2> */}
          <p className="text-gray-600 mb-4">{description.split(' ')
    .slice(0, 10)
    .join(' ') } ...</p>
          <div className="flex justify-between items-center">
            <span className="text-gray-800">INR {price}</span>
            <button onClick={handleToggleFavorite}>
              <FontAwesomeIcon
                icon={isFavorite ? faHeartSolid : faHeartRegular}
                color={isFavorite ? "#B7177B" : "#B7177B"}
                className="text-2xl"
              />
            </button>
          </div>
        </div>
      </div>

      {showConfirmPopup && (
        <ConfirmationPopup
          confirmTitle={isFavorite ? 'Remove from Favorites?' : 'Add to Favorites?'}
          confirmDesc={isFavorite ? 'Are you sure you want to remove this product from your favorites?' : 'Are you sure you want to add this product to your favorites?'}
          onClose={() => setShowConfirmPopup(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default ProductCard;
