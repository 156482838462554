import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';  // Import useLocation
// import icon from "../../assets/images/icon.png";
import companyName from "../../assets/images/icon scrunchie.png";

const Footer = () => {
  const location = useLocation();

  // Function to scroll to top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Scroll to top when the location changes
  React.useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <footer className="flex flex-col justify-center py-5 w-full text-black" style={{ backgroundColor: '#e1ca77' }}>
      <div className="flex flex-col justify-center w-10/12 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10 p-5 mt-3">
          <div>
            <div className='flex flex-row'>
              {/* <div className="mb-4">
                <img src={icon} alt="Logo" className="w-20 h-20" />
              </div> */}
              <div className="mb-4">
                <img src={companyName} alt="Logo" className="h-44" />
              </div>
            </div>

            <div className="grid grid-cols-5 gap-2 justify-center items-center">
              <NavLink to="https://youtube.com/@ourscrunchiesstudio?si=-Xqs1d3GcFYiGGAw" onClick={scrollToTop} className="text-[#B7177B] hover:text-yellow-600 transition-colors duration-300">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </NavLink>
              <NavLink to="https://www.facebook.com/profile.php?id=61566143287815&mibextid=ZbWKwL" onClick={scrollToTop} className="text-[#B7177B] hover:text-yellow-600 transition-colors duration-300">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </NavLink>
              <NavLink to="https://www.instagram.com/ourscrunchie.studio/" onClick={scrollToTop} className="text-[#B7177B] hover:text-yellow-600 transition-colors duration-300">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </NavLink>
              <NavLink to="/contactUs" onClick={scrollToTop} className="text-black hover:text-blue-600 transition-colors duration-300">
                Contact Us
              </NavLink>
            </div>
          </div>
          <div className='flex flex-col sm:justify-center md:justify-start sm:items-start md:items-start'>
            <p className="mb-4 descf py-2">Our mission is to provide you with the perfect accessory for every occasion whether you’re looking for something bold and playful or elegant and subtle, we’ve got you covered. </p>
            <p className="mb-4 descf py-2">Join us in celebrating self-expression, one scrunchie at a time!</p>
            <p className="descf pt-2">Site developed by Starting Core.</p>
            <p className="descf pt-2">Site mentored by Connecting Domes</p>
            <p className="descf pt-2">Site adviced by Intellistudy Technology Global Pvt. Ltd.</p>
            <p className="mb-4 descf pt-2"> A N.N.N. & C.A.P. Initiative</p>
          </div>
          {/* 
          <div className='flex flex-col sm:justify-center md:justify-start sm:items-start md:items-center'>
            <h3 className="mb-4 underline uppercase headings">About</h3>
            <ul className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 gap-2'>
              <li className="mb-2"><NavLink to="/aboutUs" onClick={scrollToTop} className="foots">Our Story</NavLink></li>
              <li className="mb-2"><NavLink to="/contactUs" onClick={scrollToTop} className="foots">Contact</NavLink></li>
              <li className="mb-2"><NavLink to="#" onClick={scrollToTop} className="foots">Careers</NavLink></li>
              <li className="mb-2"><NavLink to="#" onClick={scrollToTop} className="foots">Blog</NavLink></li>
            </ul>
          </div>

          <div className='flex flex-col sm:justify-center md:justify-start sm:items-start md:items-center'>
            <h3 className="mb-4 underline uppercase headings">Links</h3>
            <ul className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 gap-2'>
              <li className="mb-2"><NavLink to="/allBrands" onClick={scrollToTop} className="foots">Product Brands</NavLink></li>
              <li className="mb-2"><NavLink to="#" onClick={scrollToTop} className="foots">Sales</NavLink></li>
              <li className="mb-2"><NavLink to="/contactUs" onClick={scrollToTop} className="foots">Support</NavLink></li>
            </ul>
          </div>

          <div className='flex flex-col sm:justify-center md:justify-start sm:items-start md:items-center'>
            <h3 className="mb-4 underline uppercase headings">Shop</h3>
            <ul className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 gap-2'>
              <li className="mb-2"><NavLink to="/discoverCollection" onClick={scrollToTop} className="foots">All Products</NavLink></li>
              <li className="mb-2"><NavLink to="/latestProduct" onClick={scrollToTop} className="foots">New Arrivals</NavLink></li>
              <li className="mb-2"><NavLink to="/bestSelling" onClick={scrollToTop} className="foots">Best Sellers</NavLink></li>
              <li className="mb-2"><NavLink to="/allBrands" onClick={scrollToTop} className="foots">Sales</NavLink></li>
            </ul>
          </div> */}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-t border-gray-700 pt-6">
          <div>
            <ul className='flex flex-col md:flex-row gap-4'>
              <li className="mb-2 md:mb-0"><NavLink to="/privacy" onClick={scrollToTop} className="foots">Privacy Policy</NavLink></li>
              <li className="mb-2 md:mb-0"><NavLink to="/terms" onClick={scrollToTop} className="foots">Terms And Conditions</NavLink></li>
              <li className="mb-2 md:mb-0"><NavLink to="/refund" onClick={scrollToTop} className="foots">Refund/Return Policy</NavLink></li>
            </ul>
          </div>

          {/* Column 2: Copyright */}
          <div className="flex items-center justify-center md:justify-end">
            <p className="text-black">© 2024 Excabular Trading OPC Private Limited. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
