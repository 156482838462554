import React from "react";
import LuxuryIn from "../Layouts/LuxuryIn/LuxuryIn";
import TrustPilot from "../Layouts/TrustPilot/TrustPilot";
import Footer from "../Layouts/Footer/Footer";

const FooterPage=()=>{
    return(
        <>
        {/* <LuxuryIn/> */}
        <TrustPilot/>
        <Footer/>
        </>
    )
}

export default FooterPage;