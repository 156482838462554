import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { updateCart } from "../../redux/cart";

const CartCard = ({
  imageUrl,
  productName,
  price,
  quantity,
  productStock,  // Added productStock prop
  itemId,
  onRemove
}) => {
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  // Decrease Quantity Logic
  const handleDecrease = () => {
    if (localQuantity > 1) {  // Ensure quantity doesn't go below 1
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      dispatch(updateCart({ itemId, quantity: newQuantity }));
    }
  };

  // Increase Quantity Logic
  const handleIncrease = () => {
    if (localQuantity < 5 && localQuantity < productStock) {  // Ensure quantity doesn't exceed 5 or available stock
      const newQuantity = localQuantity + 1;
      setLocalQuantity(newQuantity);
      dispatch(updateCart({ itemId, quantity: newQuantity }));
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }
  };

  return (
    <div className="flex flex-col md:flex-row p-2 gap-4 shadow-md overflow-hidden bg-white">
      {/* Image Column */}
      <div className="w-4/12">
        <img src={imageUrl} alt={productName} className="w-80 h-44 object-cover" />
      </div>

      {/* Details Column */}
      <div className="w-6/12 flex flex-col justify-between">
        <div className="flex flex-col">
          {/* Product Name */}
          <h2 className="text-lg font-semibold mb-2 px-2">{productName}</h2>

          {/* Price */}
          <div className="flex flex-col px-2 md:flex-col justify-between gap-2 mb-2 mt-2 items-start">
            <span className="text-black font-semibold">INR {price}</span>

            {/* Quantity Adjustment */}
            <div className="flex items-center my-2">
              <button
                onClick={handleDecrease}
                disabled={localQuantity <= 1}  // Disable when quantity <= 1
                className={`bg-gray-200 text-gray-700 px-3 py-1 ${
                  localQuantity <= 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <span className="mx-3 text-gray-800">{localQuantity}</span>
              <button
                onClick={handleIncrease}
                disabled={localQuantity >= 5 || localQuantity >= productStock}  // Disable if quantity reaches 5 or stock limit
                className={`bg-gray-200 text-gray-700 px-3 py-1 ${
                  localQuantity >= 5 || localQuantity >= productStock
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            {localQuantity >= productStock && (
              <span className="text-red-500 text-sm">
                Only {productStock} left in stock.
              </span>
            )}
            {localQuantity >= 5 && (
                    <span className="text-red-500 text-sm">
                        You can add only 5 products.
                    </span>
                )}
          </div>

          {/* Remove Button */}
          <div className="flex items-center justify-end gap-2 mb-2 px-2">
            <div className="flex justify-end">
              <button onClick={handleRemove} className="text-red-500">
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CartCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  productStock: PropTypes.number.isRequired,  // Ensure productStock is passed and required
  itemId: PropTypes.string.isRequired,  // Unique ID for the item
  onRemove: PropTypes.func,  // Optional callback for removing items
};

export default CartCard;
