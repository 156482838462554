import React from 'react';
import Button from '../../Components/Button/Button'; // Adjust the import path as necessary

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li key={i}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              handlePageChange(i);
            }}
            className={`flex items-center justify-center px-3 h-8 leading-tight ${
              currentPage === i
                ? 'text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700'
                : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
            } dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-black`}
          >
            {i}
          </a>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 items-center justify-between space-x-4 p-5 shadow-md bg-white">
      {/* Previous Button */}
      <Button
        name="Previous"
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="rounded-md disabled:bg-gray-300 bg-[#e1ca77] hover:text-black text-black disabled:text-black border"
        bg="#e1ca77"
        color="white"
      />

      {/* Page Indicator */}
      <div className="text-center">
        <span className="text-lg font-semibold">
          Page {currentPage} of {totalPages}
        </span>
      </div>

      {/* Next Button */}
      <Button
        name="Next"
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className="rounded-md disabled:bg-gray-300 bg-[#e1ca77] hover:text-black text-black disabled:text-black border"
        bg="#e1ca77"
        color="white"
      />
    </div>
  );
};

export default Pagination;
