import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import "./navbar.css";
// import icon from "../../assets/images/icon.png";
// import companyName from "../../assets/images/icon scrunchie.png";
import Underline from '../../Components/Underline/Underline';
import { getAllCategories } from '../../redux/category';
import DivHeading from "../../Components/DivHeading/Divheading"

const Navbar = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.categorySlice);

  const [limit, setLimit] = useState(3);  
  // const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    dispatch(getAllCategories({
      searchValue: '',
      perPageCount: '',
      pageNumber: '',
    }));
  }, [dispatch]);

    // Function to load more categories
  const handleSeeMore = () => {
    setLimit(prevLimit => prevLimit + 3);  // Increment by 4 when "See More" is clicked
  };

  // const navItems = [
  //   // { path: '/', label: 'Home' },
  //   // { path: '/allBrands', label: 'All Scrunchies' }, // Brands

  //   // { path: '/clothing', label: 'Filter' }, // clothing


  //   ...categories.map(category => ({
  //     path: `/navproducts/${category._id}`,
  //     label: category.name,
  //     image: category.image,
  //     desc: category.description,
  //   }))
  // ];

  const navItems = [
    ...categories.slice(0, limit).map(category => (  // Limit the displayed categories
      {
        path: `/navproducts/${category._id}`,
        label: category.name,
        image: category.image,
        desc: category.description,
        outOfStock: category.name === 'Satin Scrunchies', 
      }
    ))
  ];

  // const isActive = (path) => location.pathname === path;

  return (
    <>
      {/* <nav className="bg-[#e1ca77] md:p-2 mx-auto">
    <div className="container mx-auto relative flex justify-between items-center"> */}
      {/* Logo Section for Mobile */}
      {/* {!isOpen && (
          <div className="flex flex-row items-center md:invisible">
            <div className="mb-4">
              <img src={icon} alt="Logo" className="w-10 h-10" />
            </div>
            <div className="mb-4">
              <img src={companyName} alt="Company Name" className="h-16" />
            </div>
          </div>
        )} */}

      {/* Toggle Button for Mobile */}
      {/* <div className="block lg:hidden absolute">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-black focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}></path>
            </svg>
          </button>
        </div> */}

      {/* Navigation Links */}
      {/* <div className={`w-full block lg:flex lg:items-center lg:justify-center lg:w-full hide-scrollbar overflow-x-scroll no-scrollbar ${isOpen ? '' : 'hidden'} lg:space-x-4`}>
          <div className="z-[1000] absolute top-12 left-8 md:left-10 lg:left-0 lg:relative lg:top-0 flex flex-col lg:flex-row justify-center items-end lg:items-center w-full">
            <div className='w-screen lg:w-auto flex flex-col lg:flex-row justify-center items-start lg:space-x-4 lg:items-center bg-[#e1ca77] gap-4'> */}
      <div id='featureCollection' className="flex flex-col justify-center py-5 w-11/12 mx-auto">
        <DivHeading text="Featured Collection" />
        <div className="pt-5">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 pt-5">
            {navItems.map(({ path, label, image, desc, outOfStock }) => (
                // Conditionally render the clickable Link or a non-clickable div
                outOfStock ? (
                  <div key={path} className="bg-white shadow-lg overflow-hidden w-full mx-auto cursor-not-allowed">
                    <div className="relative w-full">
                      <img className='w-full h-80 object-cover' src={image} alt={label} />
                    </div>
                    <div className="p-4">
                      <div className="flex flex-col justify-between items-start mb-2">
                        <h2 className="text-xl">{label}</h2>
                        <p className="text-gray-600">{desc.split(' ').slice(0, 25).join(' ')} ...</p>
                        {/* Show "Out of Stock" for non-clickable categories */}
                        <span className="text-red-500 font-semibold mt-2">Out of Stock</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link to={path} key={path} className="bg-white shadow-lg overflow-hidden w-full mx-auto cursor-pointer">
                    <div className="relative w-full">
                      <img className='w-full h-80 object-cover' src={image} alt={label} />
                    </div>
                    <div className="p-4">
                      <div className="flex flex-col justify-between items-start mb-2">
                        <h2 className="text-xl">{label}</h2>
                        <p className="text-gray-600">{desc.split(' ').slice(0, 25).join(' ')} ...</p>
                      </div>
                    </div>
                  </Link>
                )
              ))}
            </div>
             {/* Show "See More" button if there are more categories to display */}
            {categories.length > limit && (
              <p className="text-black-500 cursor-pointer text-center mt-4" onClick={handleSeeMore}>
                See More
              </p>
            )}
          </div>
        </div>
      </div>
      {/* </div>
          </div>
        </div> */}
      {/* </div>
    </nav> */}
    </>
  );
};

export default Navbar;
