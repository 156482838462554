import React from 'react';

const Underline = ({ width, className = '' }) => {
  return (
    <div
      style={{
        width: width,
        height: '3px', 
        backgroundColor: '#B7177B',
        margin: '0 auto', 
      }}
      className={className}
    />
  );
};

export default Underline;
