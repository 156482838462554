import React ,{ useState, useCallback,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBell, faSearch } from "@fortawesome/free-solid-svg-icons";
import debounce from 'lodash.debounce'; 
const SearchMenu = ({ title, profileImage , searchFunction,  clearSearch, }) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useCallback(
    debounce((value) => {
      console.log("Debounced Search Value:", value);
      searchFunction(value);
    }, 800), 
    []
  );

  const handleSearchChange = (e) => {
    console.log("Search Value:", e.target.value);
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    console.log("Key Pressed:", e.key);
    if (e.key === "Enter") {
      debouncedSearch.cancel();
      searchFunction(searchValue);
    }
  };

  return (
    <div className="flex items-center justify-between bg-white sm:p-4 shadow-md">
      {/* Search Bar */}
      <div className="flex items-center w-full md:w-1/2 lg:w-1/3 border border-gray-300 rounded-md overflow-hidden">
        <span className="px-2 text-gray-500">
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <input
          type="text"
          placeholder={title}
          className="w-full px-2 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchValue}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
        />
          {searchValue.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setSearchValue("");
                    clearSearch();
                  }}
                  className="text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            <FontAwesomeIcon
                icon={faTimes}
              />
                </button>
              )}
      </div>

      {/* Notification Bell and Profile Image */}
      {/* <div className="flex items-center space-x-4">
        <FontAwesomeIcon icon={faBell} className="text-gray-600 text-xl" />
        <img
          src={profileImage}
          alt="Profile"
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div> */}
    </div>
  );
};

export default SearchMenu;
