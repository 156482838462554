import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink } from 'react-router-hash-link';
import {
  // faSearch,
  faShoppingCart,
  faUser,
  // faSignOutAlt,
  // faSignInAlt,
  faHeart,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// import icon from "../../assets/images/icon.png";
import companyName from "../../assets/images/icon scrunchie.png";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { getUserData, logout } from "../../redux/auth";
import Button from "../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useAuth } from "../../Routes/AuthContext";
import debounce from "lodash/debounce";
import { getUniversalSearch } from "../../redux/product";
import { faFirstOrder, faReceipt } from "@fortawesome/free-brands-svg-icons";

const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { userData } = useSelector((state) => state.authentication);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Debounce search function
  const debouncedSearch = debounce((query) => {
    if (query.trim()) {
      dispatch(getUniversalSearch(query));
      navigate(`/search/${encodeURIComponent(searchValue)}`);
    }
  }, 600);

  const handleCartClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleLogout = () => {
    Cookies.remove("role");
    Cookies.remove("userName");
    Cookies.remove("token");
    Cookies.remove("userEmail");
    Cookies.remove("loginData");
    dispatch(logout());
    navigate("/login");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle hamburger menu
  };

  return (
    <>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={handleCloseSidebar}
        userId={userData?._id}
      />

      {isMobileMenuOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <div className="fixed top-0 right-0 w-64 h-full bg-[#e1ca77] px-5 py-8">
            <div className="flex justify-end">
              <FontAwesomeIcon
                icon={faTimes}
                className="w-6 h-4 ṣm:w-6 sm:h-6  cursor-pointer mb-4 text-end"
                onClick={toggleMobileMenu}
              />
            </div>
            <nav className="flex flex-col space-y-4 text-black font-medium">
              <NavLink to="/"
                className={({ isActive }) =>
                  isActive ? "border-b-4 border-[#B7177B]" : ""
                }
                onClick={toggleMobileMenu}>Home</NavLink>
              <NavLink to="/aboutUs" className={({ isActive }) =>
                isActive ? "border-b-4 border-[#B7177B]" : ""
              }
                onClick={toggleMobileMenu}>About Us</NavLink>
              <NavLink to="/aboutScrunchie" className={({ isActive }) =>
                isActive ? "border-b-4 border-[#B7177B]" : ""
              }
                onClick={toggleMobileMenu}>About Scrunchie</NavLink>
              <HashLink smooth to="/#featureCollection" className={({ isActive }) =>
                isActive ? "border-b-4 border-[#B7177B]" : ""
              }
                onClick={toggleMobileMenu}>Feature Collection</HashLink>
              <HashLink smooth to="/#packofscrunchie" className={({ isActive }) =>
                isActive ? "border-b-4 border-[#B7177B]" : ""
              }
                onClick={toggleMobileMenu}>Pack Of Scrunchies</HashLink>
              {/* {isLoggedIn && (
                <>
                  <NavLink to="/myprofile" onClick={toggleMobileMenu}>Profile</NavLink>
                  <NavLink to="/myOrders" onClick={toggleMobileMenu}>My Orders</NavLink>
                  <NavLink to="/favourites" onClick={toggleMobileMenu}>Favourites</NavLink>
                  <Button name={"Log Out"} bg="#B7177B" color="white" onClick={handleLogout} />
                </>
              )}
              {!isLoggedIn && (
                <Button name={"Log In"} bg="#B7177B" color="white" onClick={() => navigate("/login")} />
              )} */}
            </nav>
          </div>
        </div>
      )}

      <div className="flex w-full bg-[#e1ca77]">
        <div className="flex flex-row justify-between w-full items-center mx-auto px-2 sm:px-4 py-2">

          {/* Left Side */}
          <div className="flex flex-row items-center">
            {/* <div className="mb-4">
              <img src={icon} alt="Logo" className="w-10 h-10" />
            </div> */}
            <div className="mb-0">
              <img src={companyName} alt="Company Name" className="h-20 lg:h-36" />
            </div>
          </div>

          {/* <div className="flex space-x-4 text-black pb-4 md:pb-0">
            
          </div> */}


          {/* Right Side */}
          <div className="flex flex-row gap-4 space-x-4 text-black">
            {/* <div className="flex items-center w-full bg-white border border-gray-300 rounded-md overflow-hidden">
              <span className="px-2 text-black bg-white">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                id="searchInput"
                type="text"
                placeholder="Search..."
                className="w-full px-2 py-2 text-black focus:outline-none"
                value={searchValue}
                onChange={handleSearchChange}
              />
              {searchValue.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setSearchValue("");
                    navigate(`/`);
                  }}
                  className="text-xl font-bold text-black hover:bg-[#e1ca77] hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                >
                  &times;
                </button>
              )}
            </div> */}

            <div className="flex flex-row justify-center items-center gap-4 px-4 lg:px-0">
              {/* <div className="hidden lg:block">
                <Navbar />
              </div> */}
              <div className="flex flex-row items-center gap-4">
                {/* Desktop Links (hidden on mobile) */}
                <div className="hidden lg:flex space-x-4 text-black font-medium">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "border-b-4 border-[#B7177B]" : ""
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/aboutUs"
                    className={({ isActive }) =>
                      isActive ? "border-b-4 border-[#B7177B]" : ""
                    }
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    to="/aboutScrunchie"
                    className={({ isActive }) =>
                      isActive ? "border-b-4 border-[#B7177B]" : ""
                    }
                  >
                    About Scrunchie
                  </NavLink>
                  <HashLink smooth
                    to="/#featureCollection"
                    className={({ isActive }) =>
                      isActive ? "border-b-4 border-[#B7177B]" : ""
                    }
                  >
                    Featured Collection
                  </HashLink>
                  <HashLink smooth
                    to="/#packofscrunchie"
                    className={({ isActive }) =>
                      isActive ? "border-b-4 border-[#B7177B]" : ""
                    }
                  >
                    Pack Of Scrunchies
                  </HashLink>
                </div>

                {/* User Actions (Cart, Profile, etc.) */}
                <div className="flex items-center gap-3 sm:gap-4 text-black">
                  {isLoggedIn ? (
                    <>
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-[#B7177B] w-6 h-6  cursor-pointer"
                        onClick={() => navigate('/myprofile')}
                      />
                      <FontAwesomeIcon
                        icon={faShoppingCart}
                        className="text-[#B7177B] w-6 h-6  cursor-pointer"
                        onClick={handleCartClick}
                      />
                      <FontAwesomeIcon
                        icon={faFirstOrder}
                        className="text-[#B7177B] w-6 h-6  cursor-pointer"
                        onClick={() => { navigate('/myOrders') }}
                      />
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="text-[#B7177B] w-6 h-6  cursor-pointer"
                        onClick={() => navigate("/favourites")}
                      />
                      <Button
                        name={"Log Out"}
                        bg="#B7177B"
                        color="white"
                        onClick={handleLogout}
                        className="rounded !text-sm !py-1 !px-2"
                      />
                    </>
                  ) : (
                    <Button
                      name={"Log In"}
                      bg="#B7177B"
                      color="white"
                      onClick={() => navigate("/login")}
                      className="rounded"
                    />
                  )}
                </div>

                {/* Hamburger Icon (Mobile Only) */}
                <div className="lg:hidden flex items-center">
                  <FontAwesomeIcon
                    icon={faBars}
                    className="w-6 h-4 ṣm:w-6 sm:h-6  cursor-pointer text-black"
                    onClick={toggleMobileMenu}
                  />
                </div>
              </div>
              {/* <div className="block lg:hidden">
                <Navbar />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
