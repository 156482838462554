import React, { useEffect, useState } from 'react';
import CustomInput from '../Components/InputTag/Input';
import Button from '../Components/Button/Button';
import DivHeading from '../Components/DivHeading/Divheading';
import { getUserData,updateUser } from "../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

const MyProfile = () => {
  const { userData } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    address: {
      street: '',
      country: '',
      city: '',
      state: '',
      zipCode: '',
    }
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    address: {
      street: '',
      country: '',
      city: '',
      state: '',
      zipCode: '',
    }
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        email: userData.email || '',
        phoneNumber: userData.phone || '',
        gender: userData.gender || '',
        address: {
          street: userData.address?.street || '',
          country: userData.address?.country || '',
          city: userData.address?.city || '',
          state: userData.address?.state || '',
          zipCode: userData.address?.zipCode || '',
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.firstName) {
      newErrors.firstName = 'First name is required.';
      isValid = false;
    }

    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required.';
      isValid = false;
    }

    if (!formData.email) {
      newErrors.email = 'Email address is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid.';
      isValid = false;
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required.';
      isValid = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number is invalid. It should be a valid format with 10 digits, optionally with a country code.';
      isValid = false;
    }

    if (!formData.gender) {
      newErrors.gender = 'Gender is required.';
      isValid = false;
    }

    if (!formData.address.street) {
      newErrors.address = { ...newErrors.address, street: 'Street address is required.' };
      isValid = false;
    }

    if (!formData.address.country) {
      newErrors.address = { ...newErrors.address, country: 'Country is required.' };
      isValid = false;
    }

    if (!formData.address.city) {
      newErrors.address = { ...newErrors.address, city: 'City is required.' };
      isValid = false;
    }

    if (!formData.address.state) {
      newErrors.address = { ...newErrors.address, state: 'State is required.' };
      isValid = false;
    }

    if (!formData.address.zipCode) {
      newErrors.address = { ...newErrors.address, zipCode: 'Zip code is required.' };
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('Profile data:', formData);
     dispatch(updateUser(formData))
    }
  };

  return (
    <div className="flex flex-col gap-5 w-10/12 mx-auto my-5">
      <div className="border border-gray-300 rounded-md p-6 w-full mx-auto">
        <DivHeading text="My Profile" />
        <form className='w-full'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <CustomInput
              label="First Name"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              error={errors.firstName}
            />
            <CustomInput
              label="Last Name"
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              error={errors.lastName}
            />
            <CustomInput
              label="Email Address"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              error={errors.email}
              type="email"
            />
            <CustomInput
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
              error={errors.phoneNumber}
              type="tel"
            />
          </div>
          <div className="mt-4">
            <p className="block text-gray-700 text-sm font-bold mb-2">Gender</p>
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={formData.gender === 'Male'}
                  onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                  className="form-radio text-green-600"
                />
                <span className="ml-2">Male</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={formData.gender === 'Female'}
                  onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                  className="form-radio text-green-600"
                />
                <span className="ml-2">Female</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Other"
                  checked={formData.gender === 'Other'}
                  onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                  className="form-radio text-green-600"
                />
                <span className="ml-2">Other</span>
              </label>
            </div>
            {errors.gender && <p className="text-red-500 text-xs italic mt-1">{errors.gender}</p>}
          </div>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <CustomInput
              label="Street Address"
              value={formData.address.street}
              onChange={(e) => setFormData({ ...formData, address: { ...formData.address, street: e.target.value } })}
              error={errors.address?.street}
            />
            <CustomInput
              label="Country"
              value={formData.address.country}
              onChange={(e) => setFormData({ ...formData, address: { ...formData.address, country: e.target.value } })}
              error={errors.address?.country}
            />
            <CustomInput
              label="City"
              value={formData.address.city}
              onChange={(e) => setFormData({ ...formData, address: { ...formData.address, city: e.target.value } })}
              error={errors.address?.city}
            />
            <CustomInput
              label="State"
              value={formData.address.state}
              onChange={(e) => setFormData({ ...formData, address: { ...formData.address, state: e.target.value } })}
              error={errors.address?.state}
            />
            <CustomInput
              label="Zip Code"
              value={formData.address.zipCode}
              onChange={(e) => setFormData({ ...formData, address: { ...formData.address, zipCode: e.target.value } })}
              error={errors.address?.zipCode}
            />
          </div>
          <Button name='Save Profile' onClick={handleSubmit} bg="#e1ca77" color="white" />
        </form>
      </div>
    </div>
  );
};

export default MyProfile;
